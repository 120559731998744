// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-page-wrapper {
    display: table;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1000;
  }
  
  .loading-page {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }
  .spinner {
    display: inline-block;
    width: 100px;
    height: 100px;
    background-color: white;
    border: 8px solid #EF5F63;
    border-right-color: transparent;
    border-radius: 50%;
    animation: rotate-spinner  .5s  infinite linear;
  }
  
  @keyframes rotate-spinner {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/AuthComponents/Styles/loadingPage.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,aAAa;EACf;;EAEA;IACE,mBAAmB;IACnB,kBAAkB;IAClB,sBAAsB;EACxB;EACA;IACE,qBAAqB;IACrB,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,yBAAyB;IACzB,+BAA+B;IAC/B,kBAAkB;IAClB,+CAA+C;EACjD;;EAEA;IACE;MACE,oBAAoB;IACtB;IACA;MACE,yBAAyB;IAC3B;EACF","sourcesContent":[".loading-page-wrapper {\r\n    display: table;\r\n    width: 100%;\r\n    height: 100%;\r\n    position: absolute;\r\n    z-index: 1000;\r\n  }\r\n  \r\n  .loading-page {\r\n    display: table-cell;\r\n    text-align: center;\r\n    vertical-align: middle;\r\n  }\r\n  .spinner {\r\n    display: inline-block;\r\n    width: 100px;\r\n    height: 100px;\r\n    background-color: white;\r\n    border: 8px solid #EF5F63;\r\n    border-right-color: transparent;\r\n    border-radius: 50%;\r\n    animation: rotate-spinner  .5s  infinite linear;\r\n  }\r\n  \r\n  @keyframes rotate-spinner {\r\n    from {\r\n      transform: rotate(0);\r\n    }\r\n    to {\r\n      transform: rotate(360deg);\r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
