// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* login.css */
/*label{*/
/*    font-size: 1.2remrem;*/
/*  }*/
  
  .sign-in-button{
    background: #333;
  }
  .login-container {
    display: flex;
    flex-direction: column;
    /* height: 100vh; */
  }
  
  .login-header {
    background-color: #333; /* Dark background */
    color: #fff;
    padding: 40px 20px;
    text-align: center;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
  }
  
  .login-title, .login-subtitle {
    font-size: 1.2remrem;
  }
  
  .login-content {
    flex-grow: 1;
    /* background-color: #f3f4f6; */
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
  }
  
  .login-paper {
    padding: 20px;
    width: 100%;
    max-width: 400px;
    margin-top: 5%;
    /* background-color: #fff; */
  }
  
  .forgot-password {
    color: #1976d2;
    cursor: pointer;
  }
  
  .sign-in-button {
    height: 50px;
    font-size: 1.2remrem;
    width: 100%;
  }
  
  /*.MuiFormControl-root {*/
  /*  margin-top: 20px;*/
  /*}*/
  
  @media (max-width: 600px) {
    .login-title, .login-subtitle {
      font-size: 1.2rem;
    }
  
    .sign-in-button {
      font-size: 1.2rem;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/styles/login.css"],"names":[],"mappings":"AAAA,cAAc;AACd,SAAS;AACT,4BAA4B;AAC5B,MAAM;;EAEJ;IACE,gBAAgB;EAClB;EACA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,sBAAsB,EAAE,oBAAoB;IAC5C,WAAW;IACX,kBAAkB;IAClB,kBAAkB;IAClB,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB;EACF;;EAEA;IACE,oBAAoB;EACtB;;EAEA;IACE,YAAY;IACZ,+BAA+B;IAC/B,aAAa;IACb,6BAA6B;IAC7B,yBAAyB;EAC3B;;EAEA;IACE,aAAa;IACb,WAAW;IACX,gBAAgB;IAChB,cAAc;IACd,4BAA4B;EAC9B;;EAEA;IACE,cAAc;IACd,eAAe;EACjB;;EAEA;IACE,YAAY;IACZ,oBAAoB;IACpB,WAAW;EACb;;EAEA,yBAAyB;EACzB,sBAAsB;EACtB,IAAI;;EAEJ;IACE;MACE,iBAAiB;IACnB;;IAEA;MACE,iBAAiB;IACnB;EACF","sourcesContent":["/* login.css */\n/*label{*/\n/*    font-size: 1.2remrem;*/\n/*  }*/\n  \n  .sign-in-button{\n    background: #333;\n  }\n  .login-container {\n    display: flex;\n    flex-direction: column;\n    /* height: 100vh; */\n  }\n  \n  .login-header {\n    background-color: #333; /* Dark background */\n    color: #fff;\n    padding: 40px 20px;\n    text-align: center;\n    height: 150px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center\n  }\n  \n  .login-title, .login-subtitle {\n    font-size: 1.2remrem;\n  }\n  \n  .login-content {\n    flex-grow: 1;\n    /* background-color: #f3f4f6; */\n    display: flex;\n    /* justify-content: center; */\n    /* align-items: center; */\n  }\n  \n  .login-paper {\n    padding: 20px;\n    width: 100%;\n    max-width: 400px;\n    margin-top: 5%;\n    /* background-color: #fff; */\n  }\n  \n  .forgot-password {\n    color: #1976d2;\n    cursor: pointer;\n  }\n  \n  .sign-in-button {\n    height: 50px;\n    font-size: 1.2remrem;\n    width: 100%;\n  }\n  \n  /*.MuiFormControl-root {*/\n  /*  margin-top: 20px;*/\n  /*}*/\n  \n  @media (max-width: 600px) {\n    .login-title, .login-subtitle {\n      font-size: 1.2rem;\n    }\n  \n    .sign-in-button {\n      font-size: 1.2rem;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
