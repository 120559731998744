// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cometchat-call-log-info {
    width: 100%;
    padding-bottom: var(--cometchat-padding-3, 12px);
}

.cometchat-call-log-info .cometchat-list-item {
    background: var(--cometchat-background-color-03, #F5F5F5);
}

.cometchat-call-log-info .cometchat-list-item__leading-view,
.cometchat-call-log-info .cometchat-avatar,
.cometchat-call-log-info .cometchat-avatar__image {
    height: 16px;
    width: 16px;
    background: transparent;
    border-radius: 0px;
}

.cometchat-call-log-info__subtitle .cometchat-date {
    overflow: hidden;
    color: var(--cometchat-text-color-secondary, #727272);
    text-overflow: ellipsis;
    font: var(--cometchat-font-body-regular);
    padding: 0px;
}

.cometchat-call-log-info .cometchat-call-log-info__trailing-view {
    overflow: hidden;
    color: var(--cometchat-text-color-primary, #141414);
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    font: var(--cometchat-font-body-medium);
}

.cometchat-call-log-info .cometchat-call-log-info__trailing-view-disabled {
    overflow: hidden;
    color: var(--cometchat-text-color-tertiary, #A1A1A1);
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    font: var(--cometchat-font-body-medium);
}

.cometchat-call-log-info .cometchat-list-item__trailing-view {
    width: fit-content;
}`, "",{"version":3,"sources":["webpack://./src/styles/CometChatCallLog/CometChatCallLogInfo.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gDAAgD;AACpD;;AAEA;IACI,yDAAyD;AAC7D;;AAEA;;;IAGI,YAAY;IACZ,WAAW;IACX,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,qDAAqD;IACrD,uBAAuB;IACvB,wCAAwC;IACxC,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,mDAAmD;IACnD,iBAAiB;IACjB,uBAAuB;IACvB,mBAAmB;IACnB,uCAAuC;AAC3C;;AAEA;IACI,gBAAgB;IAChB,oDAAoD;IACpD,iBAAiB;IACjB,uBAAuB;IACvB,mBAAmB;IACnB,uCAAuC;AAC3C;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".cometchat-call-log-info {\n    width: 100%;\n    padding-bottom: var(--cometchat-padding-3, 12px);\n}\n\n.cometchat-call-log-info .cometchat-list-item {\n    background: var(--cometchat-background-color-03, #F5F5F5);\n}\n\n.cometchat-call-log-info .cometchat-list-item__leading-view,\n.cometchat-call-log-info .cometchat-avatar,\n.cometchat-call-log-info .cometchat-avatar__image {\n    height: 16px;\n    width: 16px;\n    background: transparent;\n    border-radius: 0px;\n}\n\n.cometchat-call-log-info__subtitle .cometchat-date {\n    overflow: hidden;\n    color: var(--cometchat-text-color-secondary, #727272);\n    text-overflow: ellipsis;\n    font: var(--cometchat-font-body-regular);\n    padding: 0px;\n}\n\n.cometchat-call-log-info .cometchat-call-log-info__trailing-view {\n    overflow: hidden;\n    color: var(--cometchat-text-color-primary, #141414);\n    text-align: right;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    font: var(--cometchat-font-body-medium);\n}\n\n.cometchat-call-log-info .cometchat-call-log-info__trailing-view-disabled {\n    overflow: hidden;\n    color: var(--cometchat-text-color-tertiary, #A1A1A1);\n    text-align: right;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    font: var(--cometchat-font-body-medium);\n}\n\n.cometchat-call-log-info .cometchat-list-item__trailing-view {\n    width: fit-content;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
