// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cometchat-call-log-participants {
    width: 100%;
}

.cometchat-call-log-participants .cometchat-date {
    padding: 0px;
    overflow: hidden;
    color: var(--cometchat-text-color-secondary, #727272);
    text-overflow: ellipsis;
    font: var(--cometchat-font-body-regular);
}

.cometchat-call-log-participants__trailing-view {
    overflow: hidden;
    color: var(--cometchat-text-color-primary, #141414);
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    font: var(--cometchat-font-body-medium);
}

.cometchat-call-log-participants__trailing-view-disabled {
    overflow: hidden;
    color: var(--cometchat-text-color-tertiary, #A1A1A1);
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    font: var(--cometchat-font-body-medium);
}

.cometchat-call-log-participants .cometchat-list-item__trailing-view {
    width: fit-content;
}`, "",{"version":3,"sources":["webpack://./src/styles/CometChatCallLog/CometChatCallLogParticipants.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,qDAAqD;IACrD,uBAAuB;IACvB,wCAAwC;AAC5C;;AAEA;IACI,gBAAgB;IAChB,mDAAmD;IACnD,iBAAiB;IACjB,uBAAuB;IACvB,mBAAmB;IACnB,uCAAuC;AAC3C;;AAEA;IACI,gBAAgB;IAChB,oDAAoD;IACpD,iBAAiB;IACjB,uBAAuB;IACvB,mBAAmB;IACnB,uCAAuC;AAC3C;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".cometchat-call-log-participants {\n    width: 100%;\n}\n\n.cometchat-call-log-participants .cometchat-date {\n    padding: 0px;\n    overflow: hidden;\n    color: var(--cometchat-text-color-secondary, #727272);\n    text-overflow: ellipsis;\n    font: var(--cometchat-font-body-regular);\n}\n\n.cometchat-call-log-participants__trailing-view {\n    overflow: hidden;\n    color: var(--cometchat-text-color-primary, #141414);\n    text-align: right;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    font: var(--cometchat-font-body-medium);\n}\n\n.cometchat-call-log-participants__trailing-view-disabled {\n    overflow: hidden;\n    color: var(--cometchat-text-color-tertiary, #A1A1A1);\n    text-align: right;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    font: var(--cometchat-font-body-medium);\n}\n\n.cometchat-call-log-participants .cometchat-list-item__trailing-view {\n    width: fit-content;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
