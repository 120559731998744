// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/downloadRecording.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/callRecordingEmpty.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cometchat-call-log-recordings {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.cometchat-call-log-recordings__download {
    -webkit-mask: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background: var(--cometchat-icon-color-highlight);
    -webkit-mask-size: contain;
    height: 16px;
    width: 16px;
    cursor: pointer;
}

.cometchat-call-log-recordings__subtitle .cometchat-date {
    padding: 0px;
    overflow: hidden;
    color: var(--cometchat-text-color-secondary, #727272);
    text-overflow: ellipsis;
    font: var(--cometchat-font-body-regular);
}

.cometchat-call-log-recordings__empty-state {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--cometchat-padding-2, 8px);
    flex-shrink: 0;
    align-self: stretch;
}

.cometchat-call-log-recordings__empty-state-icon {
    -webkit-mask: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) center center no-repeat;
    background: var(--cometchat-icon-color-tertiary);
    -webkit-mask-size: contain;
    height: 60px;
    width: 60px;
}

.cometchat-call-log-recordings__empty-state-text {
    color: var(--cometchat-text-color-primary, #141414);
    text-align: center;
    font: var(--cometchat-font-heading4-medium);
}`, "",{"version":3,"sources":["webpack://./src/styles/CometChatCallLog/CometChatCallLogRecordings.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,qDAAuD;IACvD,iDAAiD;IACjD,0BAA0B;IAC1B,YAAY;IACZ,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,qDAAqD;IACrD,uBAAuB;IACvB,wCAAwC;AAC5C;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,oCAAoC;IACpC,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,6EAAgF;IAChF,gDAAgD;IAChD,0BAA0B;IAC1B,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,mDAAmD;IACnD,kBAAkB;IAClB,2CAA2C;AAC/C","sourcesContent":[".cometchat-call-log-recordings {\n    width: 100%;\n    height: 100%;\n    overflow: hidden;\n}\n\n.cometchat-call-log-recordings__download {\n    -webkit-mask: url(\"../../assets/downloadRecording.svg\");\n    background: var(--cometchat-icon-color-highlight);\n    -webkit-mask-size: contain;\n    height: 16px;\n    width: 16px;\n    cursor: pointer;\n}\n\n.cometchat-call-log-recordings__subtitle .cometchat-date {\n    padding: 0px;\n    overflow: hidden;\n    color: var(--cometchat-text-color-secondary, #727272);\n    text-overflow: ellipsis;\n    font: var(--cometchat-font-body-regular);\n}\n\n.cometchat-call-log-recordings__empty-state {\n    display: flex;\n    height: 100%;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    gap: var(--cometchat-padding-2, 8px);\n    flex-shrink: 0;\n    align-self: stretch;\n}\n\n.cometchat-call-log-recordings__empty-state-icon {\n    -webkit-mask: url(\"../../assets/callRecordingEmpty.svg\") center center no-repeat;\n    background: var(--cometchat-icon-color-tertiary);\n    -webkit-mask-size: contain;\n    height: 60px;\n    width: 60px;\n}\n\n.cometchat-call-log-recordings__empty-state-text {\n    color: var(--cometchat-text-color-primary, #141414);\n    text-align: center;\n    font: var(--cometchat-font-heading4-medium);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
