// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cometchat-tab-component {
    display: flex;
    width: 100%;
    padding: 0px 8px;
    align-items: flex-start;
    gap: 8px;
    border-top: 1px solid var(--cometchat-border-color-light, #F5F5F5);
    border-right: 1px solid var(--cometchat-border-color-light, #F5F5F5);
    background: var(--cometchat-background-color-01, #FFF);
}

.cometchat-tab-component__tab {
    display: flex;
    padding: 12px 0px 10px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0;
}

.cometchat-tab-component__tab-icon {
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    background: var(--cometchat-icon-color-secondary, #A1A1A1);
    -webkit-mask-size: contain;
    cursor: pointer;
}

.cometchat-tab-component__tab-text {
    color: var(--cometchat-text-color-secondary, #727272);
    text-align: center;
    font: var(--cometchat-font-caption1-medium, 500 12px Roboto);
    cursor: pointer;
}

.cometchat-tab-component__tab-icon-active {
    background: var(--cometchat-icon-color-highlight);
}

.cometchat-tab-component__tab-text-active {
    color: var(--cometchat-text-color-highlight);
}`, "",{"version":3,"sources":["webpack://./src/styles/CometChatSelector/CometChatTabs.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,gBAAgB;IAChB,uBAAuB;IACvB,QAAQ;IACR,kEAAkE;IAClE,oEAAoE;IACpE,sDAAsD;AAC1D;;AAEA;IACI,aAAa;IACb,0BAA0B;IAC1B,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,QAAQ;IACR,SAAW;AACf;;AAEA;IACI,aAAa;IACb,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,0DAA0D;IAC1D,0BAA0B;IAC1B,eAAe;AACnB;;AAEA;IACI,qDAAqD;IACrD,kBAAkB;IAClB,4DAA4D;IAC5D,eAAe;AACnB;;AAEA;IACI,iDAAiD;AACrD;;AAEA;IACI,4CAA4C;AAChD","sourcesContent":[".cometchat-tab-component {\n    display: flex;\n    width: 100%;\n    padding: 0px 8px;\n    align-items: flex-start;\n    gap: 8px;\n    border-top: 1px solid var(--cometchat-border-color-light, #F5F5F5);\n    border-right: 1px solid var(--cometchat-border-color-light, #F5F5F5);\n    background: var(--cometchat-background-color-01, #FFF);\n}\n\n.cometchat-tab-component__tab {\n    display: flex;\n    padding: 12px 0px 10px 0px;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    gap: 4px;\n    flex: 1 0 0;\n}\n\n.cometchat-tab-component__tab-icon {\n    display: flex;\n    width: 32px;\n    height: 32px;\n    justify-content: center;\n    align-items: center;\n    background: var(--cometchat-icon-color-secondary, #A1A1A1);\n    -webkit-mask-size: contain;\n    cursor: pointer;\n}\n\n.cometchat-tab-component__tab-text {\n    color: var(--cometchat-text-color-secondary, #727272);\n    text-align: center;\n    font: var(--cometchat-font-caption1-medium, 500 12px Roboto);\n    cursor: pointer;\n}\n\n.cometchat-tab-component__tab-icon-active {\n    background: var(--cometchat-icon-color-highlight);\n}\n\n.cometchat-tab-component__tab-text-active {\n    color: var(--cometchat-text-color-highlight);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
