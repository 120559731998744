// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn {
    display: block;
    position: relative;
    width: 200px;
    height: 60px;
    background: #393E46;
    box-shadow: 0 4px 4px rgba(0, 0, 0, .3);
    border-radius: 10px;
    line-height: 55px;
    text-align: center;
    text-decoration: none;
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 5%;
    font-size: 1.2rem;
  }
  
  .btn::before {
    display: block;
    position: absolute;
    z-index: -1;
    width: 200px;
    height: 60px;
    border-radius: 10px;
    background: black;
    opacity: 0;
    content: '';
    animation: pulse 1s infinite;
  }
  
  .btn:hover::before {
    animation: none;
    opacity: .4;
    transform: scale(1.3);
    color: white;
  }
  
  .btn.is-clicked {
    background: #00ADB5;
  }
  
  .btn.is-clicked::before {
    animation: blastOut 1s;
  }
  
  @keyframes pulse {
    from {
      transform: scale(1);
      opacity: .4;
    }
    to {
      transform: scale(1.3);
      opacity: 0;
    }
  }`, "",{"version":3,"sources":["webpack://./src/pages/styles/home.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,uCAAuC;IACvC,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;IAClB,qBAAqB;IACrB,YAAY;IACZ,iBAAiB;IACjB,iBAAiB;IACjB,iBAAiB;IACjB,iBAAiB;EACnB;;EAEA;IACE,cAAc;IACd,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,iBAAiB;IACjB,UAAU;IACV,WAAW;IACX,4BAA4B;EAC9B;;EAEA;IACE,eAAe;IACf,WAAW;IACX,qBAAqB;IACrB,YAAY;EACd;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE;MACE,mBAAmB;MACnB,WAAW;IACb;IACA;MACE,qBAAqB;MACrB,UAAU;IACZ;EACF","sourcesContent":[".btn {\n    display: block;\n    position: relative;\n    width: 200px;\n    height: 60px;\n    background: #393E46;\n    box-shadow: 0 4px 4px rgba(0, 0, 0, .3);\n    border-radius: 10px;\n    line-height: 55px;\n    text-align: center;\n    text-decoration: none;\n    color: white;\n    font-size: 1.2rem;\n    font-weight: bold;\n    margin-bottom: 5%;\n    font-size: 1.2rem;\n  }\n  \n  .btn::before {\n    display: block;\n    position: absolute;\n    z-index: -1;\n    width: 200px;\n    height: 60px;\n    border-radius: 10px;\n    background: black;\n    opacity: 0;\n    content: '';\n    animation: pulse 1s infinite;\n  }\n  \n  .btn:hover::before {\n    animation: none;\n    opacity: .4;\n    transform: scale(1.3);\n    color: white;\n  }\n  \n  .btn.is-clicked {\n    background: #00ADB5;\n  }\n  \n  .btn.is-clicked::before {\n    animation: blastOut 1s;\n  }\n  \n  @keyframes pulse {\n    from {\n      transform: scale(1);\n      opacity: .4;\n    }\n    to {\n      transform: scale(1.3);\n      opacity: 0;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
